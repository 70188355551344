import {
  FileDownloadOutlined,
  FitScreenOutlined,
  getAiPack,
  getInferenceResult,
  getProjectsInferenceJobs,
  getToken,
  InferenceJobInfo,
  InferenceResult,
  initInferenceJobInfo,
  initInferenceResult,
  isError,
  isInitialInferenceJobInfo,
  isInitialInferenceResult,
  isInitialProject,
  Project,
  useInterval,
  usePageVisibility,
} from "@ovision-gis-frontend/shared"
import {
  CONTROL_SWIPE,
  generateOlMap,
  generateOsmLayer,
  generateVectorLayerForAoi,
  generateVectorLayerForLand,
  generateVectorLayerForObjectDetection,
  generateVectorLayerForSegmentation,
  generateVectorSourceFromGeoJson,
  generateVectorSourceFromWkb,
  getLayers,
  LAYER_TILE_ANALYSIS,
  LAYER_TILE_ANALYSIS_Z_INDEX,
  LAYER_VECTOR_ANALYSIS,
  LAYER_VECTOR_AOI,
  LEGEND_COLOR_LAND_COVER_AGRICULTURE_LAND,
  LEGEND_COLOR_LAND_COVER_BARELAND,
  LEGEND_COLOR_LAND_COVER_BUILDING,
  LEGEND_COLOR_LAND_COVER_DEVELOPED_SPACE,
  LEGEND_COLOR_LAND_COVER_RANGELAND,
  LEGEND_COLOR_LAND_COVER_ROAD,
  LEGEND_COLOR_LAND_COVER_TREE,
  LEGEND_COLOR_LAND_COVER_WATER,
  removeLayers,
  VECTOR_COLOR_OBJECT_DETECTION,
  getTileLayer,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { IconButton, InfoOutlined, Switch } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import { Map as OlMap, View } from "ol"
import Swipe from "ol-ext/control/Swipe"
import { Control, defaults as defaultControls, ScaleLine } from "ol/control"
import { Extent } from "ol/extent"
import TileLayer from "ol/layer/Tile"
import VectorLayer from "ol/layer/Vector"
import TileSource from "ol/source/Tile"
import VectorSource from "ol/source/Vector"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./ProjectInfoMap.module.scss"
import AnalysisDetail from "./result/AnalysisDetail"
import ControlCard from "./result/ControlCard"
import Legend, { LegendContent } from "./result/Legend"
import ResultExportPopover from "./result/ResultExportPopover"

type Props = {
  selectedProject: Project
  setSelectedProject: React.Dispatch<React.SetStateAction<Project>>
  isProjectEditing: boolean
  setIsProjectEditing: React.Dispatch<React.SetStateAction<boolean>>
  selectedProjectJob: InferenceJobInfo
  setSelectedProjectJob: React.Dispatch<React.SetStateAction<InferenceJobInfo>>
  view: View
}

function ProjectInfoMap(props: Props) {
  const isPageVisible = usePageVisibility()
  const [isInfoVisible, setIsInfoVisible] = useState<boolean>(false)
  const [isResultExportPopoverVisible, setIsResultExportPopoverVisible] = useState<boolean>(false)
  const [resultExtent, setResultExtent] = useState<Extent | null>(null)
  const [isShowResultActive, setIsShowResultActive] = useState<boolean>(true)
  const [inferenceResult, setInferenceResult] = useState<InferenceResult>(initInferenceResult)
  const mapRef = useRef<OlMap | null>(null)
  const mapContainerRef = useRef<HTMLDivElement>(null)
  const scaleLineRef = useRef<HTMLDivElement>(null)
  const [loadingMap, setLoadingMap] = useState<boolean>(false)
  const { t } = useTranslation()

  const isImagesPanelVisible = !isInitialProject(props.selectedProject) && props.selectedProject.type === "REALTIME"
  const isToolButtonDisabled =
    isInitialInferenceJobInfo(props.selectedProjectJob) || props.selectedProjectJob.status !== "COMPLETED"

  const isSingleProjectFetching =
    !isInitialProject(props.selectedProject) &&
    props.selectedProject.type === "SINGLE" &&
    !isInitialInferenceJobInfo(props.selectedProjectJob) &&
    props.selectedProjectJob.status !== "COMPLETED" &&
    props.selectedProjectJob.status !== "ERROR"

  useInterval(
    () => {
      void setProjectsInferenceJobAsync()
    },
    isPageVisible && isSingleProjectFetching ? 5000 : null,
    true,
  )

  useEffect(() => {
    if (mapRef.current) return
    if (!mapContainerRef.current || !mapRef || !scaleLineRef.current) return

    const scaleLine = new ScaleLine({
      className: styles.olScaleLine,
      target: scaleLineRef.current,
    })

    mapRef.current = generateOlMap(
      [generateOsmLayer()],
      mapContainerRef.current,
      props.view,
      defaultControls({ rotate: false }).extend([scaleLine]),
    )
    mapRef.current?.on("loadstart", () => setLoadingMap(true))
    mapRef.current?.on("loadend", () => setLoadingMap(false))

    return () => {
      scaleLine.setMap(null)
      mapRef.current?.getAllLayers().map((_layer) => mapRef.current?.removeLayer(_layer))
      mapRef.current?.un("loadstart", () => setLoadingMap(true))
      mapRef.current?.un("loadend", () => setLoadingMap(false))
      mapRef.current?.setTarget(undefined)
      mapRef.current = null
    }
  }, [mapContainerRef.current])

  useEffect(() => {
    if (!mapRef.current) return
    props.setSelectedProjectJob(initInferenceJobInfo)

    // NOTE: REALTIME인 경우 AnalysisImages 컴포넌트 사용
    if (props.selectedProject.type === "SINGLE") void setProjectsInferenceJobAsync()

    return () => {
      if (!mapRef.current) return
      props.setIsProjectEditing(false)
    }
  }, [props.isProjectEditing, props.selectedProject])

  const setProjectsInferenceJobAsync = async () => {
    if (isInitialProject(props.selectedProject)) return

    try {
      const _job = await getProjectsInferenceJobs(props.selectedProject.id)
      if (!isError(_job) && _job.totalCount === 1) props.setSelectedProjectJob(_job.inferenceJobInfoList[0])
    } catch (e) {
      captureException(e)
    }
  }

  const onFitScreenButtonClick = () => {
    if (resultExtent) mapRef.current?.getView().fit(resultExtent)
  }

  useEffect(() => {
    if (!mapRef.current) return
    const addSceneLayers = async () => {
      if (inferenceResult.sceneUriList.length === 0 || inferenceResult.aoi === "") return

      const _aoiSource = generateVectorSourceFromWkb(inferenceResult.aoi)
      const _aoiVector = generateVectorLayerForAoi(_aoiSource)
      const _aoiExtent = _aoiSource.getExtent()
      if (!_aoiExtent) {
        captureException("AOI Extent Error", (scope) => {
          scope.setContext("AOI", { aoi: inferenceResult.aoi })
          return scope
        })
        return
      }

      if (inferenceResult.sceneUriList.length === 2 && inferenceResult.sceneExtentList.length === 2) {
        // SE AI Pack
        const _sceneTileLayers: TileLayer<TileSource>[] = []
        try {
          for (let i = 0; i < inferenceResult.sceneUriList.length; i++) {
            const wmsUrl = inferenceResult.sceneWmsUrlList[i]
            const sceneUri = inferenceResult.sceneUriList[i]

            const sceneUrl = wmsUrl || sceneUri
            if (sceneUrl) {
              _sceneTileLayers.push(
                await getTileLayer(sceneUrl, _aoiExtent, LAYER_TILE_ANALYSIS, LAYER_TILE_ANALYSIS_Z_INDEX + i),
              )
            }
          }
        } catch (e) {
          captureException(e)
        }

        _sceneTileLayers.map((_layer) => {
          mapRef.current?.addLayer(_layer)
        })

        const olSwipe = new Swipe()
        olSwipe.set("name", CONTROL_SWIPE)
        mapRef.current?.addControl(olSwipe)
        olSwipe.addLayer(_sceneTileLayers[0], false)
        olSwipe.addLayer(_sceneTileLayers[1], true)
      } else if (!isInitialInferenceResult(inferenceResult)) {
        if (isInitialProject(props.selectedProject)) return
        const aiPack = props.selectedProject.aiPack.type
        const isLandCover = aiPack === "LandCoverSegmentation"
        const isInfraAiPack = aiPack.includes("Segmentation")
        const _geoJsonSource = generateVectorSourceFromGeoJson(inferenceResult.resultGisUrl)

        let _geoJsonVector
        if (isLandCover) _geoJsonVector = generateVectorLayerForLand(_geoJsonSource)
        else if (isInfraAiPack) _geoJsonVector = generateVectorLayerForSegmentation(_geoJsonSource)
        else _geoJsonVector = generateVectorLayerForObjectDetection(_geoJsonSource)

        let _sceneTileLayer: TileLayer<TileSource> | null = null
        try {
          const sceneUrl = inferenceResult.sceneWmsUrlList[0] || inferenceResult.sceneUriList[0]
          if (sceneUrl) {
            _sceneTileLayer = await getTileLayer(sceneUrl, _aoiExtent, LAYER_TILE_ANALYSIS, LAYER_TILE_ANALYSIS_Z_INDEX)
            mapRef.current?.addLayer(_sceneTileLayer)
          }
        } catch (e) {
          captureException(e)
        }
        if (_geoJsonVector) mapRef.current?.addLayer(_geoJsonVector)
      }
      mapRef.current?.addLayer(_aoiVector)
      mapRef.current?.getView().fit(_aoiExtent)
      setResultExtent(_aoiExtent)
    }

    void addSceneLayers()

    return () => {
      if (!mapRef.current) return
      mapRef.current.getControls().forEach((ctrl) => {
        if (ctrl instanceof Control && ctrl.get("name") === CONTROL_SWIPE) mapRef.current?.removeControl(ctrl)
      })
      removeLayers(mapRef.current, LAYER_VECTOR_AOI)
      removeLayers(mapRef.current, LAYER_VECTOR_ANALYSIS)
      removeLayers(mapRef.current, LAYER_TILE_ANALYSIS, true)
      setIsShowResultActive(true)
    }
  }, [inferenceResult])

  useEffect(() => {
    if (isInitialInferenceJobInfo(props.selectedProjectJob)) return
    setIsInfoVisible(props.selectedProjectJob.status !== "COMPLETED")
    if (props.selectedProjectJob.status !== "COMPLETED") return

    const setInferenceResultAsync = async () => {
      try {
        const _result = await getInferenceResult(props.selectedProjectJob.id)
        if (!isError(_result)) setInferenceResult(_result)
      } catch (e) {
        setInferenceResult(initInferenceResult)
        captureException(e)
      }
    }
    void setInferenceResultAsync()

    return () => {
      setInferenceResult(initInferenceResult)
    }
  }, [props.selectedProjectJob])

  const onShowResultSlideClick = () => {
    if (!mapRef.current) return
    getLayers(mapRef.current, LAYER_VECTOR_ANALYSIS).map((_layer) => {
      const _visible = _layer.getVisible()
      setIsShowResultActive(!_visible)
      _layer.setVisible(!_visible)
    })
  }
  const getObjectDetectionLegendContents = (): LegendContent[] => {
    if (!mapRef.current) return []
    const _layers = getLayers(mapRef.current, LAYER_VECTOR_ANALYSIS)
    const _count = _layers.map((_layer) => (_layer as VectorLayer<VectorSource>).getSource()?.getFeatures().length ?? 0)
    if (_layers.length === 1) {
      return [
        {
          text: getAiPack(props.selectedProject.aiPack.type).label,
          count: _count.at(0),
          style: { background: VECTOR_COLOR_OBJECT_DETECTION },
        },
      ]
    } else {
      return []
    }
  }

  return (
    <div className={styles.projectInfoMap}>
      <header className={styles.header}>
        <div className={styles.left}>
          <span className={styles.title}>{""}</span>
        </div>
        <div className={styles.right}>
          <IconButton
            type={"square"}
            active={isInfoVisible || undefined}
            icon={<InfoOutlined />}
            onClick={() => setIsInfoVisible((prev) => !prev)}
          />
          <IconButton
            type={"square"}
            disabled={isToolButtonDisabled}
            icon={<FitScreenOutlined />}
            onClick={onFitScreenButtonClick}
          />
          <ResultExportPopover
            className={cn(styles.resultExportPopover, isImagesPanelVisible && styles.images)}
            downloadUrl={inferenceResult.resultDownloadUrl}
            exportUrl={inferenceResult.resultGisUrl + `&access_token=${getToken()}`}
            isVisible={isResultExportPopoverVisible}
            service={inferenceResult.sceneUriList.length === 2 ? "IMAGE_IMPROVEMENT" : undefined}
            setIsVisible={setIsResultExportPopoverVisible}
          >
            <IconButton
              type={"square"}
              disabled={isToolButtonDisabled}
              icon={<FileDownloadOutlined />}
              onClick={() => setIsResultExportPopoverVisible(!isResultExportPopoverVisible)}
            />
          </ResultExportPopover>
        </div>
      </header>
      <div
        className={cn(styles.mapContainer, styles.projectInfo, isImagesPanelVisible && styles.images)}
        ref={mapContainerRef}
      >
        {!isInitialInferenceJobInfo(props.selectedProjectJob) && (
          <>
            {isInfoVisible && <AnalysisDetail data={props.selectedProjectJob} projectType={props.selectedProject.type} />}
            {props.selectedProject.aiPack.type === "LandCoverSegmentation" && (
              <Legend
                contents={[
                  { text: t("legend.bareland.label"), style: { background: LEGEND_COLOR_LAND_COVER_BARELAND } },
                  { text: t("legend.rangeland.label"), style: { background: LEGEND_COLOR_LAND_COVER_RANGELAND } },
                  {
                    text: t("legend.developedSpace.label"),
                    style: { background: LEGEND_COLOR_LAND_COVER_DEVELOPED_SPACE },
                  },
                  { text: t("legend.road.label"), style: { background: LEGEND_COLOR_LAND_COVER_ROAD } },
                  { text: t("legend.tree.label"), style: { background: LEGEND_COLOR_LAND_COVER_TREE } },
                  { text: t("legend.water.label"), style: { background: LEGEND_COLOR_LAND_COVER_WATER } },
                  {
                    text: t("legend.agricultureLand.label"),
                    style: { background: LEGEND_COLOR_LAND_COVER_AGRICULTURE_LAND },
                  },
                  { text: t("legend.building.label"), style: { background: LEGEND_COLOR_LAND_COVER_BUILDING } },
                ]}
              />
            )}
            {props.selectedProjectJob.status === "COMPLETED" && (
              <>
                {props.selectedProject.aiPack.type.endsWith("Detection") && ( // @TODO: handle service field
                  <Legend contents={getObjectDetectionLegendContents()} />
                )}
                {props.selectedProject.aiPack.type !== "SuperEnhancement" && (
                  <ControlCard className={cn(styles.controlCard, styles.left)}>
                    <Switch active={isShowResultActive} onClick={onShowResultSlideClick}>
                      {t("controlCard.showResults.label")}
                    </Switch>
                  </ControlCard>
                )}
              </>
            )}
          </>
        )}
        <progress className={cn("tile-load-progress", loadingMap && "tile-loading")} max={"100"} value={"100"} />
        <div className={styles.customScaleLine}>
          <span>©Open Street Map</span>
          <div ref={scaleLineRef} />
        </div>
      </div>
    </div>
  )
}

export default ProjectInfoMap
